import React from "react";
import autoBind from "react-autobind";
import { Link } from "react-router-dom";
import { Range } from "react-range";
import AppContext from "app-context";
import Page from "@/Page";
import api from "js/api";
import InfiniteScroll from "react-infinite-scroller";
import { gaspedaal } from "Utils/searchform";
import { numberWithDots } from "Utils/numbers";
import { decodeEntities } from "Utils/url";

import Select from "../Components/Select";

const track = {
  height: ".25rem",
  width: "100%",
  backgroundColor: "#cec9c9",
};

const thumb = {
  height: "1rem",
  width: "1rem",
  borderRadius: ".5rem",
  backgroundColor: "#2bbbad",
};

const renderTrack = ({ props, children }) => (
  <div
    className="track"
    {...props}
    style={{
      ...props.style,
      ...track,
    }}
  >
    {children}
  </div>
);

const renderThumb = ({ props }) => (
  <div
    {...props}
    style={{
      ...props.style,
      ...thumb,
    }}
  />
);

const sorts = {
  "df-a": "Sorteren",
  "pr-a": "Prijs laag naar hoog",
  "pr-d": "Prijs hoog naar laag",
  "km-a": "Km stand laag naar hoog",
  "km-d": "Km stand hoog naar laag",
  "bj-a": "Bouwjaar oud naar nieuw",
  "bj-d": "Bouwjaar nieuw naar oud",
};

window.GLOBALS.browse = {};

export class BrowsePage extends React.Component {
  static contextType = AppContext;
  initialSearch = {
    make: "",
    model: "",
    body: "",
    query: "",
    year: {
      from: 1900,
      to: new Date().getFullYear(),
    },
    price: {
      from: 0,
      to: 200000,
    },
    mileage: {
      from: 0,
      to: 250000,
    },
    hp: {
      from: 0,
      to: 1000,
    },
    page: 1,
    sort: "df-a",
  };
  state = {
    search: { ...this.initialSearch },
    cars: [],
    added: [],
    isAdding: [],
    selects: {},
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  get id() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    this.loadSearch();
    api.comparisons.get("session").then((comparison) => {
      const urls = comparison.cars.map(
        ({ initial_url, url }) => initial_url || url
      );
      const added = this.state.added.concat(urls);
      this.setState({ comparison, added });
    });
  }

  fillSearch(search) {
    return api.browse
      .data(gaspedaal.data(this.getSearch(search || this.state.search)))
      .then((response) => {
        this.setState(response);
        return response;
      });
  }

  loadSearch() {
    if (this.id) {
      api.searches
        .get(this.id)
        .then(({ data }) => this.setState({ search: data }, () => M.AutoInit()))
        .then(this.handleSearch)
        .then(this.fillSearch);
    } else {
      this.fillSearch().then(() => {
        const searchParams = new URLSearchParams(location.search);
        const sortKeys = ["make", "model"];
        const params = new Array(...searchParams);
        params.sort(
          ([key, value], [key2, value2]) =>
            sortKeys.indexOf(key) - sortKeys.indexOf(key2)
        );

        const findOptions = async () => {
          let search = {};
          for (let [key, value] of params) {
            if (!this.initialSearch.hasOwnProperty(key)) {
              continue;
            }
            const options = this.state.selects[key];
            if (options) {
              const option = options.find(
                ({ slug }) => slug == value.toLowerCase()
              );
              if (option) {
                search[key] = option.id;
                if (key == "make" && searchParams.get("model")) {
                  await this.fillSearch(search);
                }
                continue;
              }
            }
            search[key] = value;
          }
          return search;
        };

        findOptions().then((search) => {
          const fromParams = Object.keys(search).length;
          search = { ...this.initialSearch, ...search };
          this.setState(
            {
              ...(fromParams ? { search } : window.GLOBALS.browse),
              added: this.state.added || [],
            },
            () =>
              fromParams
                ? this.fillSearch().then(() => this.handleSearch())
                : M.AutoInit()
          );
        });
      });
    }
  }

  handleSaveSearch() {
    const { search, selects } = this.state;
    const changedKeys = Object.keys(search).filter(
      (key) => !_.isEqual(search[key], this.initialSearch[key])
    );
    const titleParts = [];
    const make = selects.make.find(({ id }) => search.make == id);
    const model = selects.model.find(({ id }) => search.model == id);

    if (!model) {
      titleParts.push(make.name);
    }

    changedKeys.forEach((key) => {
      const value = search[key];

      switch (key) {
        case "model":
          titleParts.push(`${make.name} ${model.name}`);
          break;
        case "body":
          titleParts.push(bodies[value]);
          break;
        case "query":
          titleParts.push(`zoekwoorden: ${value}`);
          break;
        case "year":
          titleParts.push(`jaar: ${value.from} - ${value.to}`);
          break;
        case "price":
          titleParts.push(`€ ${value.from} - ${value.to}`);
          break;
        case "mileage":
          titleParts.push(`${value.from} - ${value.to} km`);
          break;
        case "hp":
          titleParts.push(`${value.from} - ${value.to} pk`);
          break;
      }
    });

    const title = titleParts.join(", ");
    const payload = {
      title,
      data: search,
    };

    const promise = this.id
      ? api.searches.update(this.id, payload)
      : api.searches.add(payload);
    promise.then(() =>
      M.toast({
        html: this.id ? "Zoekopdracht aangepast" : "Zoekopdracht aangemaakt",
      })
    );
  }

  handleOnChange({ target }) {
    const { name, value } = target;
    const { search } = this.state;
    search[name] = value;
    if (name == "make") {
      search.model = "";
    }
    this.setState({ search }, () => {
      M.AutoInit();
      if (name != "query" && name != "sort") {
        this.fillSearch();
      }
    });
  }

  handleSearch() {
    const { search } = this.state;
    search.page = 1;
    this.setState({ searching: false, isSubmitting: true, cars: [] }, () =>
      this.search(search)
    );
  }

  handleLoadNextPage(page) {
    const { search } = this.state;
    search.page = page;
    this.search(search);
  }

  changedFields(search) {
    const s = {};

    Object.entries(search).map(([key, value]) => {
      if (!_.isEqual(this.initialSearch[key], value)) {
        s[key] = value;
      }
    });

    return s;
  }

  getSearch(search) {
    const { full } = this.state;
    return this.changedFields(
      full
        ? search
        : { make: search.make, model: search.model, sort: search.sort }
    );
  }

  search(search) {
    const url = gaspedaal.search(this.getSearch(search));
    api.browse
      .results(url)
      .then((data) => {
        const cars = this.state.cars.concat(data.cars);
        this.setState(
          { ...data, search, cars, searching: true, isSubmitting: false },
          () => {
            if (!this.id) {
              const {
                total,
                search,
                has_next,
                has_prev,
                cars,
                results,
                added,
                searching,
              } = this.state;
              window.GLOBALS.browse = {
                total,
                search,
                has_next,
                has_prev,
                cars,
                results,
                added,
                searching,
              };
            }
          }
        );
      })
      .catch(() => this.setState({ isSubmitting: false }));
  }

  showComparisonTimeout = null;
  handleAdd(data, redirect) {
    const url = data.url;
    const { isAdding } = this.state;
    isAdding.push(url);
    this.setState({ isAdding });
    api.comparisons.occasion
      .url("session", url, {
        title: decodeEntities(data.name),
        // image: decodeEntities(data.image.url),
        price: data.offers.price,
        fuel: data.fueltype,
        doors: data.numberOfDoors,
        mileage: data.mileageFromOdometer,
        brand: data.brand.name,
        model: data.model,
        year: data.productionDate,
      })
      .then((car) => {
        if (redirect) {
          this.context.redirect(`${redirect}#popup=${car.id}`);
        } else {
          const { isAdding, added, comparison } = this.state;
          added.push(url);
          isAdding.splice(isAdding.indexOf(url), 1);
          comparison.cars.push(car);
          this.setState({ isAdding, added, showComparison: true, comparison });

          if (this.showComparisonTimeout) {
            clearTimeout(this.showComparisonTimeout);
          }

          this.showComparisonTimeout = setTimeout(() => {
            this.setState({ showComparison: false });
            this.showComparisonTimeout = null;
          }, 5000);
        }
      })
      .catch(({ message }) => {
        if (message.url) {
          M.toast({ html: message.url[0] });
        }
        const { isAdding } = this.state;
        isAdding.splice(isAdding.indexOf(url), 1);
        this.setState({ isAdding });
      });
  }

  incremental(n, max) {
    const p = n / max;
    return n * p;
  }

  stepped(n, step) {
    return Math.round(n / step) * step;
  }

  select(name) {
    const { selects } = this.state;
    const options = (selects[name] || []).filter(({ count }) => count);

    return (
      <Select
        key={name}
        search
        value={this.state.search[name]}
        name={name}
        onChange={this.handleOnChange}
        items={options.map(({ name, slug, id, count }) => ({
          name: id,
          other: slug,
          value: `${name} (${numberWithDots(count)})`,
        }))}
      />
    );
  }

  getSearchChips() {
    const { search, selects } = this.state;
    const changedFields = this.changedFields(search);
    const entries = Object.entries(changedFields).filter(
      ([key, value]) => key != "sort"
    );

    const chips = entries.map(([key, value]) => {
      const options = selects[key];
      const defaultValue = this.initialSearch[key];
      if (options) {
        const option = options.find(
          ({ id, slug }) => id == value || slug == value
        );
        if (!option) {
          return { key, name: value, defaultValue };
        }
        const { name } = option;
        return { key, name, defaultValue };
      } else if (typeof value == "object") {
        return { key, name: `${value.from} - ${value.to}`, defaultValue };
      }
      return { key, name: value, defaultValue };
    });

    const closeStyle = {
      cursor: "pointer",
      float: "right",
      fontSize: "1rem",
      lineHeight: "2rem",
      paddingLeft: ".5rem",
    };

    return (
      <div>
        {chips.map(({ key, name, defaultValue }) => (
          <div
            className="chip"
            key={key}
            onClick={() =>
              this.handleOnChange({
                target: {
                  name: key,
                  value: defaultValue,
                },
              })
            }
          >
            {name}
            <i className="far fa-times" style={closeStyle} />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      total,
      search,
      cars,
      has_next,
      has_prev,
      results,
      searching,
      isSubmitting,
      isAdding,
      added,
      full,
      showComparison,
      comparison,
    } = this.state;
    const { sort, make, model, query, body, price, year, mileage, hp } = search;
    const { user } = this.context;

    const searchHasChanged = !_.isEqual(search, this.initialSearch);

    return (
      <Page container ref="page">
        {showComparison && comparison ? (
          <div className="comparison-preview">
            <Link to="/" className="comparison-title">
              <span>{comparison.title}</span>
              <span>{comparison.cars.length}</span>
            </Link>
            {comparison.cars
              .slice()
              .reverse()
              .slice(0, 5)
              .map(({ id, user_data, images, title }) => (
                <div className="occasion-preview" key={id}>
                  {images.length ? (
                    <img
                      src={images[user_data.imageIndex || 0].thumbnail}
                      height={50}
                    />
                  ) : (
                    <div className="occasion-placeholder">
                      <i className="far fa-car" />
                    </div>
                  )}
                  <span>{title}</span>
                </div>
              ))}
          </div>
        ) : null}
        <div className="row">
          <div className="input-field col s12 m6">
            {this.select("make")}
            <label>Merk</label>
          </div>
          <div className="input-field col s12 m6">
            {this.select("model")}
            <label>Model</label>
          </div>

          {full ? (
            <>
              <div className="input-field col s12">
                <input
                  value={query}
                  type="text"
                  name="query"
                  id="query"
                  onChange={this.handleOnChange}
                />
                <label htmlFor="query">Zoekwoorden</label>
              </div>
              <div className="input-field range-field col s12 m6">
                <label className="active">Prijs</label>
                <Range
                  min={0}
                  max={200000}
                  values={[price._from || price.from, price._to || price.to]}
                  onChange={([from, to]) => {
                    search.price = {
                      _from: from,
                      from: this.stepped(this.incremental(from, 200000), 250),
                      _to: to,
                      to: this.stepped(this.incremental(to, 200000), 250),
                    };
                    this.setState({ search });
                  }}
                  onFinalChange={this.fillSearch}
                  renderTrack={renderTrack}
                  renderThumb={renderThumb}
                />
                <div className="values">
                  € {price.from != 0 ? numberWithDots(price.from) : "min."} -{" "}
                  {price.to != 200000 ? numberWithDots(price.to) : "max."}
                </div>
              </div>
              <div className="input-field range-field col s12 m6">
                <label className="active">Kilometerstand</label>
                <Range
                  step={5000}
                  min={0}
                  max={250000}
                  values={[mileage.from, mileage.to]}
                  onChange={([from, to]) => {
                    search.mileage = { from, to };
                    this.setState({ search });
                  }}
                  onFinalChange={this.fillSearch}
                  renderTrack={renderTrack}
                  renderThumb={renderThumb}
                />
                <div className="values">
                  {mileage.from != 0 ? mileage.from : "min."} -{" "}
                  {mileage.to != 250000 ? mileage.to : "max."} km.
                </div>
              </div>
              <div className="input-field range-field col s12 m6">
                <label className="active">Bouwjaar</label>
                <Range
                  min={1900}
                  max={new Date().getFullYear()}
                  values={[year.from, year.to]}
                  onChange={([from, to]) => {
                    search.year = { from, to };
                    this.setState({ search });
                  }}
                  onFinalChange={this.fillSearch}
                  renderTrack={renderTrack}
                  renderThumb={renderThumb}
                />
                <div className="values">
                  {year.from} t/m {year.to}
                </div>
              </div>
              <div className="input-field range-field col s12 m6">
                <label className="active">Vermogen (PK)</label>
                <Range
                  steps={10}
                  min={0}
                  max={1000}
                  values={[hp.from, hp.to]}
                  onChange={([from, to]) => {
                    search.hp = { from, to };
                    this.setState({ search });
                  }}
                  onFinalChange={this.fillSearch}
                  renderTrack={renderTrack}
                  renderThumb={renderThumb}
                />
                <div className="values">
                  {hp.from != 0 ? hp.from : "min."} -{" "}
                  {hp.to != 1000 ? hp.to : "max."} pk
                </div>
              </div>
              <div className="input-field col s12 m6">
                {this.select("body")}
                <label>Carrosserie</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("color")}
                <label>Kleur</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("fuel")}
                <label>Brandstof</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("transmission")}
                <label>Transmissie</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("doors")}
                <label>Aantal deuren</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("weight")}
                <label>Gewicht</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("energy")}
                <label>Energie label</label>
              </div>
              <div className="input-field col s12 m6">
                {this.select("co2")}
                <label>CO2-uitstoot</label>
              </div>
            </>
          ) : null}
        </div>

        <div className="margin-bottom-1 flex">
          <a
            href="#"
            className="margin-right-auto teal-text"
            onClick={() =>
              this.setState({ full: !full }, () =>
                !full ? M.AutoInit() : null
              )
            }
          >
            <i className={"far fa-" + (full ? "minus" : "plus")} />
            <span className="margin-left-1">Uitgebreid zoeken</span>
          </a>
          {searchHasChanged ? (
            <a
              href="#"
              className="margin-left-1 teal-text"
              onClick={() =>
                user
                  ? this.handleSaveSearch()
                  : M.Modal.getInstance(this.modal).open()
              }
            >
              Zoekopdracht opslaan
            </a>
          ) : null}
        </div>

        <div className="flex space-between margin-bottom-3">
          <div className="flex">
            <button
              className="waves-effect waves-light btn"
              disabled={isSubmitting}
              type="button"
              onClick={this.handleSearch}
            >
              {isSubmitting ? (
                <i className="fad fa-spin fa-spinner-third left" />
              ) : null}
              Zoeken
              {typeof total != "undefined"
                ? ` (${numberWithDots(total)})`
                : null}
            </button>

            {searchHasChanged ? (
              <button
                className="waves-effect waves-light btn grey"
                onClick={() =>
                  this.setState(
                    {
                      cars: [],
                      searching: false,
                      search: { ...this.initialSearch },
                    },
                    () => M.AutoInit()
                  )
                }
              >
                Resetten
              </button>
            ) : null}
          </div>

          <div className="margin-left-1">
            <select
              className="browser-default"
              name="sort"
              value={sort}
              onChange={(e) => {
                this.handleOnChange(e);
                cars.length ? this.handleSearch() : null;
              }}
            >
              {Object.entries(sorts).map(([key, value]) => (
                <option value={key} key={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {this.getSearchChips()}

        {searching ? (
          !cars.length ? (
            <p>Geen resultaten gevonden</p>
          ) : (
            <p>
              <strong>{numberWithDots(results)}</strong>
            </p>
          )
        ) : null}

        {searching && cars.length ? (
          <InfiniteScroll
            pageStart={1}
            loadMore={this.handleLoadNextPage}
            hasMore={has_next}
            loader={
              <div className="d-flex center margin-top-1" key="loader">
                <div className="preloader-wrapper big active">
                  <div className="spinner-layer spinner-green-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            }
            getScrollParent={() => {
              return document.querySelector(".page");
            }}
            useWindow={false}
          >
            {cars.map((car) => (
              <div key={car.id} className="occasion">
                <a className="image" target="_blank" href={car.url}>
                  <img src={decodeEntities(car.image.url)} alt={car.name} />
                </a>
                <div className="margin-right-1">
                  {added.indexOf(car.url) != -1 ? (
                    <Link key="title" className="title" to="/">
                      {decodeEntities(car.name)}
                    </Link>
                  ) : (
                    <a
                      key="title"
                      className="title"
                      href="#"
                      onClick={() => this.handleAdd(car, "/")}
                    >
                      {decodeEntities(car.name)}
                    </a>
                  )}
                  <div className="description">
                    <span>
                      Bouwjaar: {car.productionDate},{" "}
                      {numberWithDots(car.mileageFromOdometer)} km.
                    </span>
                    <span>
                      {car.fueltype}, {car.bodyType},{" "}
                      {car.color ? `${car.color}, ` : ""}
                      {car.numberOfDoors}-deurs
                    </span>
                    <strong>€ {numberWithDots(car.offers.price)}</strong>
                  </div>
                </div>
                <div className="compare-button">
                  {isAdding.indexOf(car.url) != -1 ? (
                    <button
                      className="btn grey waves-effect waves-light "
                      disabled
                    >
                      <i className="fad fa-spin fa-spinner-third left" />
                      Vergelijken
                    </button>
                  ) : added.indexOf(car.url) != -1 ? (
                    <button className="btn waves-effect waves-light" disabled>
                      <i className="far fa-check left" />
                      Vergelijken
                    </button>
                  ) : (
                    <button
                      className="btn waves-effect waves-light"
                      onClick={() => this.handleAdd(car)}
                    >
                      <i className="far fa-plus left" />
                      Vergelijken
                    </button>
                  )}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : null}

        <div className="modal" ref={(modal) => (this.modal = modal)}>
          <div className="modal-content">
            <p>Je moet ingelogd zijn om je zoekopdracht te kunnen opslaan.</p>
          </div>
          <div className="modal-footer">
            <Link to="/login" className="waves-effect waves-light btn">
              Inloggen
            </Link>
            <button
              className="modal-close waves-effect waves-light btn-flat"
              type="button"
            >
              {__("Close")}
            </button>
          </div>
        </div>
      </Page>
    );
  }
}
