import React from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import Page from '@/Page';
import AppContext from 'app-context';
import api from 'js/api';

export class SearchesPage extends React.Component {
  static contextType = AppContext;
  state = {
    searches: []
  }

  constructor(props) {
    super(props);
    autoBind(this)
  }

  componentDidMount() {
    api.searches.list()
    .then((searches) => this.setState({ searches }))
  }

  handleDelete(id, idx) {
    const { searches } = this.state;
    delete searches[idx]
    api.searches.delete(id)
    .then(() => this.setState({ searches }))
  }

  render() {
    const { searches } = this.state;
    return <Page container>
      <h6>Zoekopdrachten</h6>
      <div className='searches'>
        {searches.length ? (
          searches.map(({ id, title, data }, idx) => (
            <div className='search' key={id}>
              <Link to={`/aanbod/${id}`} className='search-title'>{title || id}</Link>
              <div className='actions'>
                <a className='red-text' href='#' onClick={() => this.handleDelete(id, idx)}>Verwijderen</a>
              </div>
            </div>
          ))
        ) : (
          <p>Je hebt nog geen zoekopdrachten, <Link to='/aanbod' className='teal-text'>zoeken</Link>.</p>
        )}
      </div>
    </Page>
  }
}
