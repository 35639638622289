import React from "react";
import PublicComparisons from "../Components/PublicComparisons";

export const PublicComparisonsPage = () => {
  return (
    <div className="page">
      <div className="container">
        <PublicComparisons />
      </div>
    </div>
  );
};

export default PublicComparisonsPage;
