import React from "react";
import autoBind from "react-autobind";
import { Link } from "react-router-dom";
import AppContext from "app-context";
import Page from "@/Page";
import api from "js/api";

export class ComparisonListPage extends React.Component {
  static contextType = AppContext;
  state = {
    errors: {},
    title: "",
    description: "",
    visibility: "PRIVATE",
    editing: null,
    deleting: null,
    resetting: null,
    comparisons: [],
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { title, description, visibility, editing } = this.state;

    if (editing) {
      api.comparisons
        .update(editing, { title, description, visibility })
        .then((data) => {
          this.updateComparison(editing, data);
          M.toast({ html: "Opgeslagen!" });
          M.Modal.getInstance(this.modal).close();
        })
        .then(() => this.setState({ errors: {}, isSubmitting: false }))
        .catch(this.handleErrors);
    } else {
      api.comparisons
        .add({ title, description, visibility })
        .then((comparison) => {
          M.Modal.getInstance(this.modal).close();
          M.toast({ html: "Opgeslagen!" });
          let { comparisons } = this.state;
          comparisons.unshift(comparison);
          this.setState({ comparisons, errors: {}, isSubmitting: false });
        })
        .catch(this.handleErrors);
    }
  }

  handleErrors({ message }) {
    const errors = {};
    Object.keys(message).forEach(
      (key) => (errors[key] = message[key][0] || message[key])
    );
    this.setState({ errors, isSubmitting: false });
  }

  handleOnChange(e) {
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  }

  componentDidMount() {
    M.AutoInit();

    api.comparisons.list().then((comparisons) => {
      comparisons.sort(({ is_primary }) => (is_primary ? -1 : 1));
      this.setState({ comparisons }, () => M.AutoInit());
    });
  }

  findComparison(id) {
    return this.state.comparisons.find((comparison) => id == comparison.id);
  }

  updateComparison(id, data) {
    const { comparisons } = this.state;
    const comparison = this.findComparison(id);
    const index = comparisons.indexOf(comparison);
    comparisons[index] = data;
    this.setState({ comparisons });
  }

  openModal(id) {
    this.setState(
      {
        editing: id,
        ...(this.findComparison(id) || { title: "", description: "" }),
      },
      () => {
        M.updateTextFields();
        M.textareaAutoResize(this.modal.querySelector("textarea"));
        this.modal.querySelector("input").focus();
      }
    );
    M.Modal.getInstance(this.modal).open();
  }

  openResetModal(id) {
    this.setState({
      resetting: this.findComparison(id),
    });
    M.Modal.getInstance(this.resetModal).open();
  }

  openDeleteModal(id) {
    this.setState({
      deleting: this.findComparison(id),
    });
    M.Modal.getInstance(this.deleteModal).open();
  }

  handleReset(old) {
    api.comparisons.reset(old.id).then((comparison) => {
      const { comparisons } = this.state;
      const index = comparisons.indexOf(old);
      comparisons[index] = comparison;
      M.Modal.getInstance(this.resetModal).close();
      M.toast({ html: "Gereed" });
      this.setState({ comparisons });
    });
  }

  handleDelete(comparison) {
    api.comparisons.delete(comparison.id).then(() => {
      const { comparisons } = this.state;
      const index = comparisons.indexOf(comparison);
      M.Modal.getInstance(this.deleteModal).close();
      M.toast({ html: "Verwijderd" });
      comparisons.splice(index, 1);
      this.setState({ comparisons });
    });
  }

  render() {
    const {
      comparisons,
      isSubmitting,
      title,
      description,
      visibility,
      errors,
      editing,
      deleting,
      resetting,
    } = this.state;

    return (
      <Page container>
        <div>
          <a
            className="waves-effect waves-light btn margin-right-1"
            onClick={() => this.openModal()}
          >
            {__("Add")}
          </a>
        </div>

        <div className="row">
          {comparisons.map(
            ({ id, title, description, cars, created, visibility }) => (
              <div className="col s12 l6" key={id}>
                <div className="card">
                  <Link to={`/${id}`} className="card-content black-text">
                    <div className="card-title margin-bottom-1 flex align-center">
                      <img
                        src="/favicon.png"
                        style={{ height: "1.5rem", marginRight: ".5rem" }}
                      />
                      {title}

                      <div className="margin-left-auto">
                        {visibility === "PRIVATE" && (
                          <i className="fa fa-lock" />
                        )}
                        {visibility === "PUBLIC" && (
                          <i className="fa fa-globe-europe" />
                        )}
                        {visibility === "UNLISTED" && (
                          <i className="fa fa-user-secret" />
                        )}
                      </div>
                    </div>
                    <div
                      className="description"
                      data-placeholder="Omschrijving"
                    >
                      {description}
                    </div>
                    <div>
                      Aangemaakt op: <strong>{created}</strong>
                    </div>
                    <div>
                      Occasions: <strong>{cars.length}</strong>
                    </div>
                  </Link>
                  <div className="card-action flex space-between">
                    <div>
                      <a
                        href="#"
                        className="tooltipped teal-text"
                        onClick={() => this.openModal(id)}
                        data-tooltip="Wijzigen"
                      >
                        <i className="far fa-pencil" />
                      </a>
                      <a
                        href="#"
                        className="tooltipped"
                        onClick={() => this.openResetModal(id)}
                        data-tooltip="Resetten"
                      >
                        <span className="fa-stack">
                          <i className="fas fa-cars teal-text" />
                          <i className="far fa-trash-alt red-text" />
                        </span>
                      </a>
                    </div>
                    <a
                      href="#"
                      className="tooltipped red-text margin-right-0"
                      onClick={() => this.openDeleteModal(id)}
                      data-tooltip="Verwijderen"
                    >
                      <i className="far fa-trash-alt" />
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className="modal" ref={(modal) => (this.resetModal = modal)}>
          <div className="modal-content">
            {resetting ? (
              <p>
                Weet je zeker dat je <strong>{resetting.title}</strong> wilt
                resetten?
                <br />
                Deze actie kan niet ongedaan worden gemaakt.
              </p>
            ) : null}
          </div>
          <div className="modal-footer">
            <button
              className="modal-close waves-effect waves-green btn-flat"
              type="button"
            >
              {__("No")}
            </button>
            <button
              className="waves-effect waves-red btn red"
              disabled={isSubmitting}
              onClick={() => this.handleReset(resetting)}
            >
              {__("Yes")}
            </button>
          </div>
        </div>

        <div className="modal" ref={(modal) => (this.deleteModal = modal)}>
          <div className="modal-content">
            {deleting ? (
              <p>
                Weet je zeker dat je <strong>{deleting.title}</strong> wilt
                verwijderen?
                <br />
                Deze actie kan niet ongedaan worden gemaakt.
              </p>
            ) : null}
          </div>
          <div className="modal-footer">
            <button
              className="modal-close waves-effect waves-green btn-flat"
              type="button"
            >
              {__("No")}
            </button>
            <button
              className="waves-effect waves-red btn red"
              disabled={isSubmitting}
              onClick={() => this.handleDelete(deleting)}
            >
              {__("Yes")}
            </button>
          </div>
        </div>

        <form
          className="modal"
          ref={(modal) => (this.modal = modal)}
          onSubmit={this.handleOnSubmit}
        >
          <div className="modal-content">
            <div className="input-field">
              <input
                id="title"
                type="text"
                className={"validate" + (errors.title ? " invalid" : "")}
                name="title"
                value={title}
                onChange={this.handleOnChange}
                disabled={isSubmitting}
              />
              <label htmlFor="title">Titel</label>
              <span className="helper-text" data-error={errors.title}></span>
            </div>
            <div className="input-field">
              <textarea
                id="description"
                className={
                  "materialize-textarea validate" +
                  (errors.description ? " invalid" : "")
                }
                name="description"
                value={description}
                onChange={this.handleOnChange}
                disabled={isSubmitting}
              />
              <label htmlFor="description">Omschrijving</label>
              <span
                className="helper-text"
                data-error={errors.description}
              ></span>
            </div>

            <div className="input-field">
              <p>
                <label>
                  <input
                    name="visibility"
                    type="radio"
                    value="PUBLIC"
                    checked={visibility === "PUBLIC"}
                    onChange={this.handleOnChange}
                  />
                  <span>Openbaar</span>
                </label>
              </p>
            </div>
            <div className="input-field">
              <p>
                <label>
                  <input
                    name="visibility"
                    type="radio"
                    value="UNLISTED"
                    checked={visibility === "UNLISTED"}
                    onChange={this.handleOnChange}
                  />
                  <span>Niet vermeld, alleen met link</span>
                </label>
              </p>
            </div>
            <div className="input-field">
              <p>
                <label>
                  <input
                    name="visibility"
                    type="radio"
                    value="PRIVATE"
                    checked={visibility === "PRIVATE"}
                    onChange={this.handleOnChange}
                  />
                  <span>Privé</span>
                </label>
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="modal-close waves-effect waves-light btn-flat"
              type="button"
            >
              {__("Close")}
            </button>
            <button
              className="waves-effect waves-light btn"
              disabled={isSubmitting}
            >
              {__("Save")}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}
