import React from "react";
import { Redirect } from "react-router-dom";
import PublicComparisons from "../Components/PublicComparisons";

export const HomePage = ({ settings, user }) => {
  if (user) {
    return <Redirect to="/vergelijkingen" />;
  }

  return (
    <div className="page">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: settings.homepage_content }} />
        <PublicComparisons />
      </div>
    </div>
  );
};

export default HomePage;
