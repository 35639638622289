import React from 'react';
import cn from 'classnames'
import PropTypes from 'prop-types';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.scss';

import slide1 from '../static/slides/slide-1.jpeg'
import slide2 from '../static/slides/slide-2.jpeg'
import slide3 from '../static/slides/slide-3.jpeg'
import slide4 from '../static/slides/slide-4.jpeg'
import slide5 from '../static/slides/slide-5.jpeg'

// install Swiper modules
SwiperCore.use([Pagination]);


const SLIDES = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
]


export default class Page extends React.Component {
  static propTypes = {
    container: PropTypes.bool,
    withSwiper: PropTypes.bool,
  }
  static defaultProps = {
    container: false,
    withSwiper: false,
  }

  render() {
    const { container, children, className, withSwiper, ...props } = this.props;
    return <div {...props} ref={el => this.el = el} className={cn(className, 'page', { 'with-swiper': withSwiper })}>
      {withSwiper ? (
        <>
          <Swiper
            slidesPerView={1}
            centeredSlides
            pagination={{
              clickable: false
            }}
            autoPlay={{
              delay: 1000,
              disableOnInteraction: false
            }}
            loop
          >
            {SLIDES.map((slide, i) => (
              <SwiperSlide
                key={i}
                style={{
                  backgroundImage: `url(${slide})`
                }}
              />
            ))}
          </Swiper>
          <div>{children}</div>
        </>
      ) : (
        container ? <div className='container'>{children}</div> : children
      )}
    </div>
  }
}
