import { fetch } from "whatwg-fetch";

const env = process.env.NODE_ENV || "development";
const isDev = env == "development";
window.GLOBALS = {};
window.GLOBALS.env = env;
window.GLOBALS.isDev = isDev;
// window.GLOBALS.url = isDev
//   ? `http://192.168.2.100:8000`
//   : "https://api.vergelijkoccasions.nl";
window.GLOBALS.url = "https://api.vergelijkoccasions.nl";

const api = {
  url: window.GLOBALS.url,
  storageName: "user",
  _user: {},

  async handleFetch(response) {
    const data = response.status != 204 ? await response.json() : {};

    if (!response.ok) {
      response.message = data;
      throw response;
    }

    return data;
  },

  abort() {
    this.controller.abort();
  },

  request(method, path, data, addHeaders = true) {
    let url = this.url + path;
    let headers = addHeaders
      ? this.headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        })
      : null;
    let request = { method, headers };
    if (method == "DELETE") return fetch(url, request);
    if (data) request.body = JSON.stringify(data);

    this.controller = new AbortController();
    return fetch(url, request, { signal: this.controller.signal }).then(
      this.handleFetch
    );
  },
  raw(path, defaultHeaders = {}) {
    let headers = this.headers(defaultHeaders);
    let request = { method: "GET", headers };
    let url = this.url + path;
    return fetch(url, request, { signal: this.controller.signal });
  },
  media(path) {
    return this.raw(this.url.replace("api", "media") + path);
  },
  static(path) {
    return this.raw(this.url.replace("api", "static") + path);
  },
  formData(path, data, method = "POST") {
    let url = this.url + path;
    let headers = this.headers();
    let request = { method, body: data, headers };
    return fetch(url, request, { signal: this.controller.signal }).then(
      this.handleFetch
    );
  },
  get: (path) => api.request("GET", path),
  delete: (path) => api.request("DELETE", path),
  patch: (path, data) => api.request("PATCH", path, data),
  put: (path, data) => api.request("PUT", path, data),
  post: (path, data) => api.request("POST", path, data),

  _get: (path) => api.request("GET", path, null, false),

  headers(defaultHeaders = {}) {
    let { token } = this.getUser();
    if (!token) {
      return defaultHeaders;
    }
    return {
      ...defaultHeaders,
      Authorization: `Token ${token}`,
    };
  },

  getUser() {
    if (Object.keys(this._user).length) {
      return this._user;
    }
    return JSON.parse(localStorage.getItem(this.storageName) || "{}");
  },

  isAuthenticated() {
    return this.get("/auth/check/")
      .then((user) => user && this.saveUser(user))
      .catch(() => this.removeUser());
  },

  settings() {
    return this.get("/settings/");
  },

  saveUser(user) {
    localStorage.setItem(this.storageName, JSON.stringify(user));
    this._user = user;
    return this._user;
  },

  removeUser() {
    localStorage.removeItem(this.storageName);
    this._user = {};
  },

  login({ username, password }) {
    return this.post("/auth/login/", { username, password }).then(
      this.saveUser.bind(this)
    );
  },

  register(data) {
    return this.post("/auth/register/", data).then(this.saveUser.bind(this));
  },

  loginWithProvider(provider, access_token) {
    const data = {
      provider,
      access_token,
    };

    return this.post("/oauth/login/", data).then(this.saveUser.bind(this));
  },

  loginWithFacebook(token) {
    return this.loginWithProvider("facebook", token);
  },

  loginWithApple(token) {
    return this.loginWithProvider("apple", token);
  },

  logout() {
    return this.get("/auth/logout/").then(this.removeUser.bind(this));
  },

  password: {
    reset: (data) => api.post("/auth/password/reset/", data),
    check: (uidb64, token) =>
      api.get(`/auth/password/check/${uidb64}/${token}/`),
    set: (uidb64, token, data) =>
      api.post(`/auth/password/set/${uidb64}/${token}/`, data),
  },

  profile: {
    get: () => api.get("/profile/"),
    post: (data) => api.post("/profile/", data),
  },

  browse: {
    data: (url) => api.post("/browse/data/", { url }),
    results: (url) => api.post("/browse/", { url }),
  },

  comparisons: {
    list: () => api.get("/comparisons/"),
    public: () => api.get("/comparisons/public/"),
    add: (data) => api.post("/comparisons/", data),
    get: (id) => api.get(`/comparisons/${id}/`),
    toggle: (id) => api.post(`/comparisons/${id}/toggle/`),
    update: (id, data) => api.put(`/comparisons/${id}/`, data),
    delete: (id) => api.delete(`/comparisons/${id}/`),
    sort: (id, data) => api.post(`/comparisons/${id}/sort/`, data),
    setActive: (id) => api.post(`/comparisons/${id}/set-active/`),
    reset: (id) => api.post(`/comparisons/${id}/reset/`),
    resetFields: (id) => api.post(`/comparisons/${id}/reset-fields/`),
    setFields: (id, fields) =>
      api.post(`/comparisons/${id}/fields/`, { fields }),
    occasion: {
      url: (id, url, data) =>
        api.post(`/comparisons/${id}/add-url/`, { url, data }),
      add: (id, field) => api.post(`/comparisons/${id}/add-field/`, { field }),
      remove: (id, field) =>
        api.post(`/comparisons/${id}/remove-field/`, { field }),
      sideToSide: (id, occasion) =>
        api.get(`/comparisons/${id}/side-to-side/${occasion}/`),
      pdf: (id, occasion) => api.raw(`/comparisons/${id}/pdf/${occasion}/`),
      reset: (id, occasion) =>
        api.post(`/comparisons/${id}/reset/${occasion}/`),
      refresh: (id, occasion) =>
        api.post(`/comparisons/${id}/refresh/${occasion}/`),
      rating: (id, occasion, rating) =>
        api.post(`/comparisons/${id}/rating/${occasion}/`, { rating }),
      update: (id, occasion, data) =>
        api.post(`/comparisons/${id}/update/${occasion}/`, data),
      updateNotes: (id, occasion, notes) =>
        api.post(`/comparisons/${id}/update-notes/${occasion}/`, { notes }),
      delete: (id, occasion) =>
        api.delete(`/comparisons/${id}/delete/${occasion}/`),
    },
  },

  searches: {
    list: () => api.get("/searches/"),
    add: (data) => api.post("/searches/", data),
    get: (id) => api.get(`/searches/${id}/`),
    delete: (id) => api.delete(`/searches/${id}/`),
    update: (id, data) => api.put(`/searches/${id}/`, data),
  },
};

export default api;
