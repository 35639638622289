import React from 'react';
import autoBind from 'react-autobind';
import Page from '@/Page';
import AppContext from 'app-context';
import api from 'js/api';

export class RegisterPage extends React.Component {
  static contextType = AppContext;
  state = {
    isSubmitting: false,
    errors: {},
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    password2: '',
  }

  constructor(props) {
    super(props);
    autoBind(this)
  }

  handleOnChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true })
    const { email, password, password2 } = this.state
    api.register({ email, password, password2 })
    .then((user) => {
      this.context.setUser(user, { redirect: '/' })
    })
    .catch(this.handleErrors)
  }

  handleErrors({ message }) {
    const errors = {}
    Object.keys(message).forEach((key) => errors[key] = message[key][0] || message[key])
    this.setState({ errors, isSubmitting: false })
  }

  render() {
    const { isSubmitting, email, password, password2, errors } = this.state;

    return <Page withSwiper>
      <form onSubmit={this.handleOnSubmit}>
        <div className='input-field'>
          <input id='email' type='text' className={'validate' + (errors.email ? ' invalid' : '')} name='email' value={email} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='email'>{__('Email')}</label>
          <span className='helper-text' data-error={errors.email}></span>
        </div>
        <div className='input-field'>
          <input id='password' type='password' className={'validate' + (errors.password ? ' invalid' : '')} name='password' value={password} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='password'>{__('Password')}</label>
          <span className='helper-text' data-error={errors.password}></span>
        </div>
        <div className='input-field'>
          <input id='password2' type='password' className={'validate' + (errors.password2 ? ' invalid' : '')} name='password2' value={password2} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='password2'>Wachtwoord bevestigen</label>
          <span className='helper-text' data-error={errors.password2}></span>
        </div>
        
        {errors.non_field_errors ? (
          <div className='form-error red-text'>{errors.non_field_errors}</div>
        ) : null}
        
        <button className='btn waves-effect waves-light' type='submit' disabled={isSubmitting}>
          {isSubmitting ? (
            <i className='fad fa-spin fa-spinner-third right' />
          ) : null}
          Registreren
        </button>
      </form>
    </Page>
  }
}
