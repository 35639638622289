export const hpToKw = (hp) => Math.round(hp * 0.745699872);
export const buildURL = (url, parts, params) => {
  let query = Object.entries(params)
    .map(([key, val]) => `${key.replace(" ", "-")}=${val}`)
    .join("&");
  return `${url}/${parts
    .map((part) => part.replace(" ", "-"))
    .join("/")}?${query}`;
};

export const gaspedaal = {
  url: "https://www.gaspedaal.nl",
  getParams: (search) => {
    const params = {};
    const mapping = {
      make: "merk",
      model: "model",
      fuel: "brnst",
      btw: "btw",
      body: "crs",
      doors: "drn",
      co2: "f_co2_uitstoot_nr",
      energy: "f_energielabel",
      weight: "f_gewicht_nr",
      color: "klr",
      transmission: "trns",
      query: "trefw",
      page: "page",
      sort: "srt",
    };

    Object.entries(mapping).forEach(([field, to]) => {
      const value = search[field];
      if (value) {
        params[to] = value;
      }
    });

    if (search.year && search.year.from) {
      params.bmin = search.year.from;
    }
    if (search.year && search.year.to) {
      params.bmax = search.year.to;
    }
    if (search.price && search.price.from) {
      params.pmin = search.price.from;
    }
    if (search.price && search.price.to) {
      params.pmax = search.price.to;
    }
    if (search.mileage && search.mileage.from) {
      params.kmin = search.mileage.from;
    }
    if (search.mileage && search.mileage.to) {
      params.kmax = search.mileage.to;
    }
    if (search.hp && search.hp.from) {
      params.vmin = hpToKw(search.hp.from);
    }
    if (search.hp && search.hp.to) {
      params.vmax = hpToKw(search.hp.to);
    }
    return params;
  },
  data: (search) => {
    let params = gaspedaal.getParams(search);
    return buildURL(gaspedaal.url, ["searchbox"], params);
  },
  search: (search) => {
    let params = gaspedaal.getParams(search);
    return buildURL(gaspedaal.url, ["zoeken"], params);
  },
};
