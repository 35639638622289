import React from 'react';
import ReactDOM from 'react-dom';
import * as _ from 'lodash';

import 'url-search-params-polyfill';
import 'materialize-css';
import 'materialize-css/sass/materialize.scss';
import 'typeface-ubuntu';
import 'typeface-ubuntu-mono';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import './css/app.scss';
import App from './app';

const script = document.createElement('script')
script.type = "text/javascript";
script.src = `${window.GLOBALS.url}/i18n.js`
document.body.appendChild(script)

script.onload = function() {
  window.__ = django.gettext;

  ReactDOM.render(
    React.createElement(App),
    document.getElementById('app'),
  );
}
