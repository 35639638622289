import React from 'react';

import Page from '@/Page';


export class PageNotFound extends React.Component {

  render() {
    return <Page container>
      <h1>Pagina niet gevonden</h1>
    </Page>
  }
}