import React from "react";
import { Link } from "react-router-dom";
import autoBind from "react-autobind";
import Page from "@/Page";
import AppContext from "app-context";
import api from "js/api";
import "js/apple_sdk";

export class LoginPage extends React.Component {
  static contextType = AppContext;
  state = {
    isSubmitting: false,
    errors: {},
    username: "",
    password: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { username, password } = this.state;
    api
      .login({ username, password })
      .then(this.handleUserLogin)
      .catch(this.handleErrors.bind(this));
  }

  handleUserLogin(user) {
    this.context.setUser(user);
    this.props.history.push("/vergelijkingen");
  }

  handleErrors({ message }) {
    const errors = {};
    Object.keys(message).forEach(
      (key) => (errors[key] = message[key][0] || message[key])
    );
    this.setState({ errors, isSubmitting: false });
  }

  handleOnChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAppleLogin() {
    AppleID.auth.init({
      clientId: "nl.vergelijkoccasions.signin",
      scope: "email",
      redirectURI: "https://vergelijkoccasions.nl",
      state: "signin",
      usePopup: true,
    });
    AppleID.auth.signIn().then(this.handleAppleLoginResponse);
  }

  handleAppleLoginResponse({ authorization }) {
    api.loginWithApple(authorization.code).then(this.handleUserLogin);
  }

  render() {
    const { isSubmitting, username, password, errors } = this.state;

    return (
      <Page withSwiper>
        <form onSubmit={this.handleOnSubmit.bind(this)}>
          <div className="input-field">
            <input
              id="username"
              type="text"
              className={"validate" + (errors.username ? " invalid" : "")}
              name="username"
              value={username}
              onChange={this.handleOnChange.bind(this)}
              disabled={isSubmitting}
            />
            <label htmlFor="username">{__("Email")}</label>
            <span className="helper-text" data-error={errors.username}></span>
          </div>
          <div className="input-field">
            <input
              id="password"
              type="password"
              className={"validate" + (errors.password ? " invalid" : "")}
              name="password"
              value={password}
              onChange={this.handleOnChange.bind(this)}
              disabled={isSubmitting}
            />
            <label htmlFor="password">{__("Password")}</label>
            <span className="helper-text" data-error={errors.password}></span>
          </div>

          {errors.non_field_errors ? (
            <div className="form-error red-text">{errors.non_field_errors}</div>
          ) : null}

          <div className="flex space-between">
            <button
              className="btn waves-effect waves-light"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <i className="fad fa-spin fa-spinner-third right" />
              ) : null}
              Inloggen
            </button>
            <Link to="/registreren">Registreren</Link>
          </div>
        </form>
      </Page>
    );
  }
}
