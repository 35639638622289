import React from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import AppContext from 'app-context';
import Page from '@/Page';
import api from 'js/api';
import { formatPlate } from 'Utils/plate'

export class OccasionAddPage extends React.Component {
  static contextType = AppContext;
  state = {
    licence_plate: '',
  }

  constructor(props) {
    super(props);
    autoBind(this)
  }

  handleOnChange(e) {
    const re = /^[\w\-]+$/;
    const value = e.target.value;
    if (value === '' || re.test(value)) {
       this.setState({ licence_plate: value.replace(/\-/g, '').toUpperCase().substr(0, 6) })
    }
  }

  render() {
    const { licence_plate } = this.state;

    return <Page container>
      <div className='plate'>
        <span>NL</span>
        <input type='text' className='browser-default' value={formatPlate(licence_plate) || licence_plate} onChange={this.handleOnChange} />
      </div>
    </Page>
  }
}
