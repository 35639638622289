import React, { useEffect, useContext } from "react";
import autoBind from "react-autobind";
import {
  BrowserRouter,
  Route,
  Link,
  NavLink,
  Switch,
  useLocation,
  useHistory,
  Redirect,
  withRouter,
} from "react-router-dom";
import {
  HomePage,
  LoginPage,
  LoginExtensionPage,
  ProfilePage,
  RegisterPage,
  BrowsePage,
  ComparisonPage,
  ComparisonListPage,
  PublicComparisonsPage,
  PageNotFound,
  InfoPage,
} from "Pages";
import AppContext from "app-context";
import api from "js/api";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function History({ app }) {
  app.state.app.history = useHistory();

  const { redirect } = app.state;
  if (redirect) {
    app.state.redirect = null;
    return <Redirect to={redirect} />;
  }
  return null;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ page, ...rest }) {
  const { user } = useContext(AppContext);
  const Element = page;
  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Element {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

const Header = withRouter(({ settings, user, logout }) => {
  return (
    <div className="header">
      <div className="container">
        <a href={settings.url}>
          <img src={settings.logo} className="logo" />{" "}
          <strong>{settings.name}</strong>
        </a>
        <ul>
          {user && (
            <li>
              <NavLink to="/vergelijkingen" exact>
                <i className="far fa-steering-wheel" /> Mijn vergelijkingen
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to={user ? "/vergelijkingen/alle" : "/"} exact>
              <i className="far fa-cars" /> Vergelijkingen
            </NavLink>
          </li>
        </ul>
        <ul style={{ marginLeft: "auto" }}>
          {user ? (
            <>
              <li>
                <NavLink to="/profiel">
                  <i className="far fa-user" />{" "}
                  {user.first_name ? user.first_name : user.email}
                </NavLink>
              </li>
              {/* <li>
            <Link to='/zoekopdrachten' onClick={() => this.setState({ menu: false })}>
              <i className='far fa-bookmark' /> Zoekopdrachten
            </Link>
          </li> */}
              <li>
                <a onClick={logout} className="red-text">
                  <i className="far fa-sign-out" /> Uitloggen
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink to="/login">
                  <i className="far fa-user-secret" />
                  <span>Inloggen</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
});

export default class App extends React.Component {
  state = {
    loading: true,
    settings: {},
    app: {
      pathname: location.pathname,
      user: null,
      language: "nl",
      setUser: (user, rest) => {
        const { app } = this.state;
        app.user = user;
        const state = {
          app,
          ...rest,
        };
        this.setState(state);
      },
      mergeUser: (newUser, rest) => {
        const { user, setUser } = this.state.app;
        setUser({ ...user, ...newUser }, rest);
      },
      logout: () => this.logout(),
      redirect: (redirect) => this.redirect(redirect),
      app: this,
    },
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { app } = this.state;

    const usingChrome = /Chrome/i.test(navigator.userAgent);
    const hideWebstore = localStorage.getItem("hideWebstore") || false;
    this.setState({ usingChrome, hideWebstore });

    api
      .isAuthenticated()
      .then((user) => {
        if (user) {
          app.user = user;
        }
      })
      .then(() => this.setState({ app }))
      .then(() => this.setState({ loading: false }));

    api.settings().then((settings) => this.setState({ settings }));
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  logout() {
    const { app } = this.state;
    app.user = null;
    api.logout().then(() => this.setState({ app, redirect: "/login" }));
  }

  handleToggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  hideWebstore(e) {
    e.preventDefault();
    this.setState({ hideWebstore: true });
    localStorage.setItem("hideWebstore", "1");
  }

  render() {
    const { menu, loading, app, settings } = this.state;
    const { user } = app;

    return (
      <AppContext.Provider value={app}>
        <BrowserRouter app={this.app}>
          <ScrollToTop />
          <History app={this} />
          {loading ? (
            <div className="preloader" />
          ) : (
            <div className="app">
              <Header
                user={user}
                settings={settings}
                logout={() => this.logout()}
              />
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <HomePage {...props} settings={settings} user={user} />
                  )}
                />
                <Route path="/registreren" exact component={RegisterPage} />
                <Route path="/login" exact component={LoginPage} />
                <Route
                  path="/login/extension"
                  exact
                  component={LoginExtensionPage}
                />
                {/* <Route path='/aanbod' exact component={BrowsePage} /> */}
                <Route path="/meer-informatie" exact component={InfoPage} />
                <Route
                  path="/vergelijkingen/alle"
                  exact
                  component={PublicComparisonsPage}
                />
                <PrivateRoute
                  path="/vergelijkingen"
                  exact
                  page={ComparisonListPage}
                />
                {/* <PrivateRoute path='/zoekopdrachten' exact page={SearchesPage} /> */}
                <PrivateRoute path="/aanbod/:id" exact page={BrowsePage} />
                {/*<Route path='/aanbieden' exact component={OccasionAddPage} />*/}
                <PrivateRoute path="/profiel" exact page={ProfilePage} />
                <Route path="/:id" exact component={ComparisonPage} />
                <Route path="*" component={PageNotFound} />
              </Switch>

              {/* {usingChrome && !hideWebstore ? (
            <a className='webstore fixed' target='_blank' href='https://chrome.google.com/webstore/detail/vergelijk-occasions/lbflglbpohmjnohhkfholfhghnobflbd?hl=nl'>
              <div className='icon'>
                <img src='/logo.png' />
              </div>
              <div className='body'>
                <span className='far fa-times close' onClick={this.hideWebstore} />
                <span>Vergelijkoccasions</span>
                <div>Toevoegen aan Chrome</div>
              </div>
            </a>
            ) : null} */}
            </div>
          )}
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}
