import React from "react";
import { Link } from "react-router-dom";

import Page from "@/Page";

export class InfoPage extends React.Component {
  state = {};

  componentDidMount() {
    const usingChrome = /Chrome/i.test(navigator.userAgent);
    this.setState({ usingChrome });
  }

  render() {
    const { usingChrome } = this.state;
    return (
      <Page container>
        <h5>Hoe werkt het</h5>
        <p>
          Om occasions te kunnen vergelijken, heb je minimaal twee occasions
          nodig. Deze moeten worden gezocht en worden toegevoegd aan de
          vergelijking. Dit kan via de onderstaande methodes:
        </p>
        <ol>
          <li>
            via een externe occasion aanbieder, zoals: marktplaats.nl,
            autotrack.nl, autoscout24.nl
            <br />
            kopieer hier het internet adres van en plak dat in
            vergelijkoccasions door te klikken op de knop
            <br />
            <button className="btn-floating grey">
              <i className="far fa-plus-circle" />
            </button>
          </li>
          {/* <li>
          via <Link to='/aanbod'>vergelijkoccasions.nl/aanbod</Link>: geef je zoekcriteria op en klik op de knop<br />
          <button className='btn'><i className='far fa-plus left' /> Vergelijken</button>
        </li> */}
        </ol>

        <h5>Occasions zoeken</h5>
        <ol>
          <li>
            <a href="https://autoscout24.nl" target="_blank">
              autoscout24.nl
            </a>
          </li>
          <li>
            <a href="https://marktplaats.nl/l/auto-s/" target="_blank">
              marktplaats.nl
            </a>
          </li>
          <li>
            <a href="https://autotrack.nl" target="_blank">
              autotrack.nl
            </a>
          </li>
          <li>
            <a href="https://autotrader.nl" target="_blank">
              autotrader.nl
            </a>
          </li>
          <li>
            <a href="https://viabovag.nl" target="_blank">
              viabovag.nl
            </a>
          </li>
          <li>
            En nog meer, we kunnen ongeveer 90% van de advertenties op de
            verschillende websites op nemen in onze vergelijking
          </li>
        </ol>
      </Page>
    );
  }
}
