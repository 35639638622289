import React from 'react';
import autoBind from 'react-autobind';
import Page from '@/Page';
import AppContext from 'app-context';
import api from 'js/api';

export class ProfilePage extends React.Component {
  static contextType = AppContext;
  state = {
    isSubmitting: false,
    errors: {},
    username: '',
    email: '',
    first_name: '',
    last_name: '',
  }

  constructor(props) {
    super(props);
    autoBind(this)
  }

  componentDidMount() {
    this.setState(this.context.user, () => M.updateTextFields())
  }

  handleOnChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true })
    const { email, first_name, last_name } = this.state
    api.profile.post({ email, first_name, last_name })
    .then((user) => {
      M.toast({ html: 'Opgeslagen!' })
      this.context.mergeUser(user)
      this.setState({ ...user, isSubmitting: false })
    })
    .catch(this.handleErrors)
  }

  handleErrors({ message }) {
    const errors = {}
    Object.keys(message).forEach((key) => errors[key] = message[key][0] || message[key])
    this.setState({ errors, isSubmitting: false })
  }

  render() {
    const { isSubmitting, email, first_name, last_name, errors } = this.state;

    return <Page container>
      <form onSubmit={this.handleOnSubmit}>
        <div className='input-field'>
          <input id='email' type='text' className={'validate' + (errors.email ? ' invalid' : '')} name='email' value={email} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='email'>{__('Email')}</label>
          <span className='helper-text' data-error={errors.email}></span>
        </div>
        <div className='input-field'>
          <input id='first_name' type='text' className={'validate' + (errors.first_name ? ' invalid' : '')} name='first_name' value={first_name} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='first_name'>Voornaam</label>
          <span className='helper-text' data-error={errors.first_name}></span>
        </div>
        <div className='input-field'>
          <input id='last_name' type='text' className={'validate' + (errors.last_name ? ' invalid' : '')} name='last_name' value={last_name} onChange={this.handleOnChange} disabled={isSubmitting} />
          <label htmlFor='last_name'>Achternaam</label>
          <span className='helper-text' data-error={errors.last_name}></span>
        </div>
        
        {errors.non_field_errors ? (
          <div className='form-error red-text'>{errors.non_field_errors}</div>
        ) : null}
        
        <div className='flex space-between'>
          <button className='btn waves-effect waves-light' type='submit' disabled={isSubmitting}>
            {isSubmitting ? (
              <i className='fad fa-spin fa-spinner-third right' />
            ) : null}
            {__('Save')}
          </button>
        </div>
      </form>
    </Page>
  }
}
