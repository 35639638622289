import React from "react";
import cn from "classnames";
import autoBind from "react-autobind";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { useRouteMatch, Link } from "react-router-dom";
import api from "js/api";
import allFields from "Data/fields.json";
import allOptions from "Data/options.json";
import AppContext from "app-context";

import Car from "@/Car";
import Page from "@/Page";

const locked = (
  <div className="car empty-slot locked">
    <div className="title">
      Deze plek ontgrendelen
      <div className="price">€ 0,99</div>
    </div>
    <div className="add">
      <i className="far fa-lock" />
    </div>
  </div>
);

const Handle = SortableHandle(() => <i className="far fa-grip-lines handle" />);

const SortableCar = SortableElement(({ page, car, children, ...props }) => (
  <Car
    data={car}
    refreshing={car.refreshing}
    setRating={page.setRating}
    handleOnReset={page.handleOnReset}
    handleOnRefresh={page.handleOnRefresh}
    handleOnSave={page.handleOnSave}
    handleOnDelete={page.openDeleteModal}
    handleUpdateData={page.handleUpdateData}
    handleOnNotes={page.handleOnNotes}
    handleOnFieldChange={page.handleOnFieldChange}
    {...props}
  >
    {children}
  </Car>
));

const CarsContainer = SortableContainer(
  ({ items, children, canEdit, extra }) => (
    <div className="cars-container" key="cars-container">
      {items.map((car, index) => (
        <SortableCar
          index={index}
          car={car}
          key={car.id}
          canEdit={canEdit}
          {...extra}
        >
          {canEdit && <Handle key="handle" />}
        </SortableCar>
      ))}
      {children}
    </div>
  )
);

export class ComparisonPage extends React.Component {
  static contextType = AppContext;
  state = {
    errors: {},
    sorted: {},
    isSubmitting: false,
    isLoaded: false,
    deleting: null,
    editing: null,
    query: "",
    url: "",
    id: 0,
    title: "",
    description: "",
    fields: [],
    notes: {},
    cars: [],
    beenHereBefore: localStorage.getItem("beenHere") ? true : false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  get id() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const { history } = this.props;
    this.materializeInit();

    api.comparisons
      .get(this.id)
      .then((data) => this.setState({ isLoaded: true, ...data }))
      .then(() => {
        if (!this.state.beenHereBefore) {
          const instance = M.Modal.getInstance(this.firstTimeModal);
          instance.options.dismissible = false;
          instance.open();
        }
      })
      .then(() => {
        const params = new URLSearchParams(location.hash.replace("#", "?"));
        const openPopup = params.get("popup");
        if (openPopup) {
          location.hash = "";
          const modal = document.querySelector(
            `[data-href="#occasion-${openPopup}"]`
          );
          if (modal) {
            M.Modal.init(modal).open();
          }
        }
      })
      .catch((response) => {
        history.push("/");
        M.toast({ html: "Vergelijking niet gevonden" });
      });
  }

  handleOnSortFieldsEnd({ oldIndex, newIndex }) {
    this.setState(({ fields }) => ({
      fields: arrayMove(fields, oldIndex, newIndex),
    }));
  }

  handleOnSortCarsEnd({ oldIndex, newIndex }) {
    if (oldIndex == newIndex) {
      return;
    }
    const newState = ({ cars }) => ({
      cars: arrayMove(cars, oldIndex, newIndex),
    });
    const afterNewState = () => {
      const newSortnumbers = this.state.cars.map(({ id }, index) => ({
        id,
        index: index + 1,
      }));
      api.comparisons.sort(this.id, { sort_numbers: newSortnumbers });
    };
    this.setState(newState, afterNewState);
  }

  handleOnRefresh(id) {
    const { cars } = this.state;
    const index = cars.indexOf(this.findCar(id));
    cars[index].refreshing = true;
    this.setState({ cars });
    api.comparisons.occasion.refresh(this.id, id).then((car) => {
      M.toast({ html: "Ververst!" });
      const index = cars.indexOf(this.findCar(id));
      cars.splice(index, 1, car);
      this.setState({ cars }, () => this.materializeInit());
    });
  }

  handleOnReset(id) {
    api.comparisons.occasion.reset(this.id, id).then((car) => {
      const { cars } = this.state;
      const index = cars.indexOf(this.findCar(id));
      cars.splice(index, 1, car);
      this.setState({ cars });
    });
  }

  handleOnSave(id, data) {
    api.comparisons.occasion.update(this.id, id, data).then((car) => {
      const { cars } = this.state;
      const index = cars.indexOf(this.findCar(id));
      cars.splice(index, 1, car);
      this.setState({ cars });
    });
  }

  setRating(id, rating) {
    const { cars } = this.state;
    const car = this.findCar(id);
    rating = car.rating == rating ? 0 : rating;
    api.comparisons.occasion.rating(this.id, id, rating).then((car) => {
      const index = cars.indexOf(this.findCar(id));
      cars.splice(index, 1, car);
      this.setState({ cars });
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { url, cars } = this.state;
    api.comparisons.occasion
      .url(this.id, url)
      .then((car) => {
        M.toast({ html: "Toegevoegd!" });
        M.Modal.getInstance(this.addModal).close();
        cars.push(car);
        this.setState({ cars, url: "", isSubmitting: false });
      })
      .catch(this.handleErrors);
  }

  handleErrors({ message = [] }) {
    const errors = {};
    Object.keys(message).forEach(
      (key) =>
        (errors[key] = Array.isArray(message[key])
          ? message[key][0]
          : message[key])
    );
    this.setState({ errors, isSubmitting: false });
  }

  handleOnChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  findCar(id) {
    return this.state.cars.find((car) => id == car.id);
  }

  openDeleteModal(id) {
    this.setState({
      deleting: this.findCar(id),
    });
    M.Modal.getInstance(this.deleteModal).open();
  }

  openAddModal() {
    M.Modal.getInstance(this.addModal).open();
  }

  openShareModal() {
    M.Modal.getInstance(this.shareModal).open();
    M.Tooltip.getInstance(this.shareButton).close();
  }

  openModal(modal) {
    M.Modal.getInstance(modal).open();
  }

  removeField(field) {
    this.setState({ isSubmitting: true });
    api.comparisons.occasion
      .remove(this.id, field)
      .then(({ fields }) => this.setState({ fields, isSubmitting: false }));
  }

  addField(field) {
    this.setState({ isSubmitting: true });
    api.comparisons.occasion
      .add(this.id, field)
      .then(({ fields }) => this.setState({ fields, isSubmitting: false }));
  }

  handleDelete(car) {
    const { cars } = this.state;
    const index = cars.indexOf(car);
    if (index < 0) return;
    this.setState({ isSubmitting: true });
    api.comparisons.occasion
      .delete(this.id, car.id)
      .then(() => {
        M.Modal.getInstance(this.deleteModal).close();
        cars.splice(index, 1);
        this.setState({ cars, isSubmitting: false });
      })
      .catch(this.handleErrors);
  }

  handleSave() {
    const { title, fields } = this.state;
    this.setState({ isSubmitting: true });
    api.comparisons.update(this.id, { title, fields }).then(() => {
      this.setState({ editing: false, isSubmitting: false, menu: false });
    });
  }

  handleOnFieldChange(field, id) {
    const { cars } = this.state;
    const car = this.findCar(id);
    const index = cars.indexOf(car);
    cars[index].user_data[field.name] = field.value;
    this.setState({ cars: _.cloneDeep(cars) });
  }

  handleOnNotes(id) {
    const car = this.findCar(id);
    const notes = {
      id,
      text: car.notes,
    };
    this.setState({ notes }, () => {
      M.Modal.getInstance(this.notesModal).open();
      M.updateTextFields();
      M.textareaAutoResize(this.notesModal.querySelector("textarea"));
    });
  }

  handleOnNotesSave() {
    const { notes } = this.state;
    const { id, text } = notes;
    this.setState({ isSubmitting: true });
    api.comparisons.occasion
      .updateNotes(this.id, id, text)
      .then(({ notes }) => {
        const { cars } = this.state;
        const index = cars.indexOf(this.findCar(id));
        cars[index].notes = notes;
        this.setState({ cars, isSubmitting: false });
        M.toast({ html: "Notitie opgeslagen" });
        M.Modal.getInstance(this.notesModal).close();
      })
      .catch(() => this.setState({ isSubmitting: false }));
  }

  handleOnNotesChange(e) {
    const { notes } = this.state;
    notes.text = e.target.value;
    this.setState({ notes });
  }

  handleToggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  handleToggleSort(field) {
    let { sorted } = this.state;
    const isToggle = sorted.field == field;

    sorted.field = field;
    if (isToggle) {
      if (sorted.dir == "down") {
        sorted = {};
      } else {
        sorted.dir = "down";
      }
    } else {
      sorted.dir = "up";
    }

    this.setState({ sorted });
  }

  handleCopy(copy) {
    const input = document.getElementById("copy");
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
    M.toast({ html: "Gekopieerd" });
  }

  handleResetFields() {
    api.comparisons
      .resetFields(this.id)
      .then(({ fields }) => this.setState({ fields }));
  }

  materializeInit() {
    M.Tooltip.init(document.querySelectorAll(".tooltipped"));
    M.Modal.init(document.querySelectorAll(".modal"));
  }

  canEdit() {
    const { visibility, user_id } = this.state;
    const { user } = this.context;
    const isMine = user && user.id == user_id;
    return !!(isMine || (visibility === "UNLISTED" && user));
  }

  render() {
    const { user } = this.context;
    let {
      errors,
      sorted,
      menu,
      editing,
      deleting,
      query,
      adding,
      isLoaded,
      isSubmitting,
      id,
      url,
      title,
      description,
      fields,
      cars,
      user_id,
      user_name,
      visibility,
      created,
      notes,
    } = this.state;
    const SortableItem = SortableElement(({ field }) => (
      <li className="no-select">
        {__(field)}
        <a
          className="waves-effect waves-light red delete"
          onClick={() => (isSubmitting ? null : this.removeField(field))}
        >
          <i className="fas fa-minus" />
        </a>
        <Handle />
      </li>
    ));

    const SortableList = SortableContainer(({ base, items }) => {
      return (
        <ul className="sortable-list editable-list">
          {items.map((value) => (
            <SortableItem
              key={value}
              index={base.indexOf(value)}
              field={value}
            />
          ))}
        </ul>
      );
    });

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    const isOption = (value, index, self) => allOptions.indexOf(value) != -1;
    const isNotOption = (value, index, self) => allOptions.indexOf(value) == -1;

    const base = fields.filter(onlyUnique);
    const options = base.filter(isOption);
    fields = base.filter(isNotOption);

    let fieldsToAdd = allFields
      .slice()
      .filter((field) => base.indexOf(field) == -1)
      .filter(onlyUnique);

    if (query) {
      const q = query.toLowerCase();
      fieldsToAdd = fieldsToAdd.filter(
        (field) => __(field).toLowerCase().indexOf(q) >= 0
      );
    }

    const className = "flex compare" + (menu ? " open" : "");
    const shareLink = `${location.origin}/${id}`;
    const sortedCars = cars.slice();

    if (Object.keys(sorted)) {
      sortedCars.sort((carA, carB) => {
        const isUp = sorted.dir == "up";
        let a = carA[sorted.field];
        let b = carB[sorted.field];

        a = isNaN(a) ? a : parseFloat(a);
        b = isNaN(b) ? b : parseFloat(b);

        if (!a) {
          return isUp ? -1 : 1;
        }

        if (!b) {
          return isUp ? 1 : -1;
        }

        if (a > b) {
          return isUp ? 1 : -1;
        } else if (a < b) {
          return isUp ? -1 : 1;
        }

        return 0;
      });
    }

    return (
      <Page ref={(el) => (this.page = el)}>
        <div className={className}>
          <div className="backdrop" onClick={this.handleToggleMenu} />
          <div className="features">
            <div className="toggle">
              <button
                className="btn-small waves-effect waves-light"
                onClick={this.handleToggleMenu}
              >
                <i className="far fa-arrow-from-left" />
              </button>
            </div>
            <div className="block">
              <div className="title">
                <Link to="/vergelijkingen" className="black-text">
                  {title}
                </Link>
                {visibility !== "PRIVATE" && (
                  <div className="button">
                    <button
                      className="btn-floating btn-small waves-effect waves-light tooltipped"
                      data-tooltip="Delen"
                      ref={(el) => (this.shareButton = el)}
                      onClick={this.openShareModal}
                    >
                      <i className="far fa-share-alt left" />
                    </button>
                  </div>
                )}
              </div>

              <div style={{ fontSize: ".85rem" }}>
                <span style={{ marginRight: 8 }}>
                  {visibility === "PRIVATE" && <i className="fa fa-lock" />}
                  {visibility === "PUBLIC" && (
                    <i className="fa fa-globe-europe" />
                  )}
                  {visibility === "UNLISTED" && (
                    <i className="fa fa-user-secret" />
                  )}
                </span>
                <strong>{user_name}</strong> ({created})
              </div>

              <div className="description">{description}</div>

              {this.canEdit() && (
                <div
                  className="comparison-actions"
                  style={{ marginTop: ".5rem" }}
                >
                  <button
                    className="btn-small waves-effect waves-light settings"
                    onClick={() =>
                      editing
                        ? this.handleSave()
                        : this.setState({ editing: !editing }, () =>
                            this.materializeInit()
                          )
                    }
                  >
                    {editing ? (
                      <>
                        <i className="far fa-save left" /> Opslaan
                      </>
                    ) : (
                      <>
                        <i className="far fa-cog left" /> Kenmerken
                      </>
                    )}
                  </button>
                  {editing ? (
                    <button
                      className="btn-small grey waves-effect waves-light reset tooltipped"
                      data-tooltip="Standaard kenmerken"
                      onClick={this.handleResetFields}
                    >
                      <i className="far fa-undo" />
                    </button>
                  ) : null}
                </div>
              )}
            </div>
            {editing ? (
              <>
                <SortableList
                  items={fields}
                  base={base}
                  onSortEnd={this.handleOnSortFieldsEnd}
                  lockAxis="y"
                  helperClass="moving"
                  useDragHandle
                />
                {options.length ? (
                  <>
                    <div className="extra-options">Extra opties</div>
                    <SortableList
                      items={options}
                      base={base}
                      onSortEnd={this.handleOnSortFieldsEnd}
                      lockAxis="y"
                      helperClass="moving"
                      useDragHandle
                    />
                  </>
                ) : null}

                <div className={"all-fields" + (adding ? " open" : "")}>
                  <div className="add-fields">
                    <div className="search">
                      <input
                        value={query}
                        id="search"
                        placeholder="Zoeken"
                        onChange={({ target }) =>
                          this.setState({ query: target.value })
                        }
                      />
                      <i className="far fa-search" />
                    </div>
                    <ul className="editable-list">
                      {fieldsToAdd.map((field) => (
                        <li
                          key={field}
                          onClick={() =>
                            isSubmitting ? null : this.addField(field)
                          }
                          className="no-select"
                        >
                          {__(field)}
                          <a className="waves-effect waves-light green add">
                            <i className="fas fa-plus" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {adding ? (
                    <button
                      className="btn-flat waves-effect waves-light"
                      onClick={() => this.setState({ adding: false })}
                    >
                      <i className="far fa-minus left" /> Sluiten
                    </button>
                  ) : (
                    <button
                      className="btn-flat waves-effect waves-light"
                      onClick={() => this.setState({ adding: true })}
                    >
                      <i className="far fa-plus left" /> Toevoegen
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <ul>
                  {fields.map((field, index) => (
                    <li
                      key={field}
                      className="sortable no-select"
                      onClick={() => this.handleToggleSort(field)}
                    >
                      {__(field)}
                      <span className="sort">
                        {sorted.field == field ? (
                          <i className={`fad fa-sort-${sorted.dir}`} />
                        ) : null}
                      </span>
                    </li>
                  ))}
                </ul>
                {options.length ? (
                  <>
                    <div className="extra-options">Extra opties</div>
                    <ul>
                      {options.map((field, index) => (
                        <li
                          key={field}
                          className="sortable no-select"
                          onClick={() => this.handleToggleSort(field)}
                        >
                          {__(field)}
                          <span className="sort">
                            {sorted.field == field ? (
                              <i className={`fad fa-sort-${sorted.dir}`} />
                            ) : null}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </>
            )}
          </div>
          <div className="cars">
            {isLoaded ? (
              <CarsContainer
                extra={{
                  page: this,
                  fields,
                  options,
                }}
                canEdit={this.canEdit()}
                items={sortedCars}
                lockAxis="x"
                axis="x"
                onSortEnd={this.handleOnSortCarsEnd}
                helperClass="car moving"
                helperContainer={() =>
                  document.querySelector(".cars-container")
                }
                lockOffset={["0%", "100%"]}
                lockToContainerEdges
                useDragHandle
              >
                {this.canEdit() &&
                  new Array(cars.length < 4 ? 4 - cars.length : 1)
                    .fill("")
                    .map((s, index) => (
                      <div
                        className="car empty-slot"
                        key={index}
                        onClick={this.openAddModal}
                      >
                        <div className="title" />
                        <div className="add">
                          <i className="far fa-plus-circle" />
                        </div>
                      </div>
                    ))}
              </CarsContainer>
            ) : (
              <div className="cars-container preloader">
                <div className="preloader-wrapper big active">
                  <div className="spinner-layer spinner-green-only">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                      <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="modals">
          <div className="modal" ref={(modal) => (this.notesModal = modal)}>
            <div className="modal-content">
              <div className="input-field">
                <textarea
                  id="notes"
                  className="materialize-textarea"
                  name="notes"
                  value={notes.text}
                  onChange={this.handleOnNotesChange}
                  disabled={isSubmitting}
                />
                <label htmlFor="notes">Notitie</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="waves-effect waves-green btn"
                type="button"
                disabled={isSubmitting}
                onClick={this.handleOnNotesSave}
              >
                {isSubmitting ? (
                  <i className="fad fa-spin fa-spinner-third left" />
                ) : null}
                Opslaan
              </button>
              <button
                className="modal-close waves-effect waves-green btn-flat"
                type="button"
              >
                {__("Close")}
              </button>
            </div>
          </div>

          <div className="modal" ref={(modal) => (this.deleteModal = modal)}>
            <div className="modal-content">
              {deleting ? (
                <p>
                  Weet je zeker dat je <strong>{deleting.title}</strong> uit de
                  vergelijking wilt verwijderen?
                </p>
              ) : null}
            </div>
            <div className="modal-footer">
              <button
                className="modal-close waves-effect waves-green btn-flat"
                type="button"
              >
                {__("No")}
              </button>
              <button
                className="waves-effect waves-red btn red"
                disabled={isSubmitting}
                onClick={() => this.handleDelete(deleting)}
              >
                {__("Yes")}
              </button>
            </div>
          </div>

          <form
            className="modal"
            ref={(modal) => (this.addModal = modal)}
            onSubmit={this.handleOnSubmit}
            noValidate
          >
            {!user && cars.length >= 3 ? (
              <>
                <div className="modal-content">
                  <p>
                    Om meer dan 3 occasions toe te voegen moet je ingelogd zijn.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    className="modal-close waves-effect waves-light btn-flat"
                    type="button"
                  >
                    {__("Close")}
                  </button>
                  <Link to="/login" className="waves-effect waves-light btn">
                    Inloggen
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="modal-content">
                  <div className="input-field">
                    <input
                      id="url"
                      type="text"
                      className={cn({ invalid: errors.url })}
                      name="url"
                      value={url}
                      onChange={this.handleOnChange}
                      disabled={isSubmitting}
                    />
                    <label htmlFor="url">Internet adres</label>
                    <span className="helper-text">
                      <p>Kopieer het internet adres van een occasion</p>
                      <Link to="/meer-informatie">Meer informatie</Link>
                    </span>
                    <span className="helper-text" data-error={errors.url} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="modal-close waves-effect waves-light btn-flat"
                    type="button"
                  >
                    {__("Close")}
                  </button>
                  <button
                    className="waves-effect waves-light btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <i className="fad fa-spin fa-spinner-third left" />
                    ) : null}
                    Toevoegen
                  </button>
                </div>
              </>
            )}
          </form>

          <div
            className="modal modal-fixed-footer"
            ref={(modal) => (this.firstTimeModal = modal)}
          >
            <div className="modal-content">
              <h5>Vergelijkoccasions.nl</h5>
              <p>
                We waren op zoek naar een occasion en we stuurde verschillende
                occasions naar elkaar toe, met als gevolg dat we geen overzicht
                meer hadden. De ene auto was op de ene website (bijvoorbeeld
                autotrack) en de andere stond op de andere website (marktplaats)
                en een derde bij een garagebedrijf. Dit probleem hebben we
                opgelost door verschillende occasions van verschillende websites
                naast elkaar te zetten.
              </p>
              <h5>Cookies</h5>
              <p>
                We plaatsen verschillende soorten cookies op onze website. Deze
                zijn noodzakelijk om de website goed te laten werken en het
                gebruik te kunnen meten. Uit deze cookies zijn geen persoonlijke
                gegevens te herleiden.
              </p>
              <p>
                Door op ‘Doorgaan’ te klikken ga je akkoord met het plaatsen van
                deze cookies.
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="modal-close waves-effect waves-light btn"
                type="button"
                onClick={() => localStorage.setItem("beenHere", "1")}
              >
                Doorgaan
              </button>
            </div>
          </div>

          <div className="modal" ref={(modal) => (this.shareModal = modal)}>
            <div className="modal-content">
              <h5>Delen</h5>
              {user && user.id ? (
                <div className="share">
                  <div className="socials">
                    <a
                      className="twitter"
                      href={`http://twitter.com/share?text=&url=${shareLink}&hashtags=vergelijk,occasions`}
                      target="_blank"
                    >
                      <i className="fab fa-twitter icon" />
                      <span className="name">Twitter</span>
                    </a>
                    <a
                      className="facebook"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                      target="_blank"
                    >
                      <i className="fab fa-facebook icon" />
                      <span className="name">Facebook</span>
                    </a>
                    <a
                      className="whatsapp"
                      href={`https://web.whatsapp.com/send?text=${shareLink}`}
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp icon" />
                      <span className="name">Whatsapp</span>
                    </a>
                    <a
                      className="mail"
                      href={`mailto:?body=${shareLink}`}
                      target="_blank"
                    >
                      <i className="fas fa-envelope" />
                      <span className="name">Mailen</span>
                    </a>
                  </div>

                  <h6>Link</h6>
                  <div className="copy">
                    <input
                      value={shareLink}
                      id="copy"
                      readOnly
                      onClick={(e) => e.target.focus()}
                    />
                    <a href="#" onClick={() => this.handleCopy(shareLink)}>
                      <i className="far fa-copy" />
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <p>Je moet ingelogd zijn om te kunnen delen.</p>
                  <Link to="/login" className="waves-effect waves-light btn">
                    Inloggen
                  </Link>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                className="modal-close waves-effect waves-light btn-flat"
                type="button"
              >
                {__("Close")}
              </button>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
