import React from 'react';
import autoBind from 'react-autobind';

export default class Select extends React.Component {
  state = {
    opened: false,
    value: this.props.value,
    query: '',
  }

  constructor(props) {
    super(props)
    autoBind(this)
  }

  get style() {
    const { input } = this.refs

    return {
      display: 'block',
      opacity: 1,
      width: input.offsetWidth,
      top: 8,
      left: 0,
    }
  }

  getItem = (name) => name ? (this.props.items.find((item) => (name == item.name) || (item.other && item.other == name))) : { name: '', value: '' }

  handleOnChange(name) {
    const { onChange } = this.props;
    const item = this.getItem(name)
    const target = {
      name: this.props.name,
      value: item.name,
    }
    onChange({ target })
    this.setState({ value: name, opened: false, query: '' })
  }

  handleOnQueryChange(e) {
    this.setState({ query: e.target.value })
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.value != this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  handleKeyDown(e) {
    let { highlighted, value } = this.state;
    const items = this.filteredItems;
    switch (e.keyCode) {
      case 38:
      case 40:
        const add = e.keyCode == 40 ? 1 : -1
        const index = items.indexOf(this.getItem(highlighted)) + add
        highlighted = items[index].name || highlighted
        this.scrollTo(index)
        this.setState({ highlighted })
        break;

      case 13:
        this.setState({ opened: false, value: highlighted }, () => this.handleOnChange(highlighted))
        break;

      case 27:
        this.setState({ opened: false })
        break;
    }
  }

  scrollTo(index) {
    const itemsOffset = 2
    this.refs.dropdown.scrollTop = (index - itemsOffset) * 54
  }

  scrollToSelected() {
    const { opened, value } = this.state;
    const { items } = this.props;

    if (!opened || !value) {
      return
    }

    const index = items.indexOf(this.getItem(value))
    this.scrollTo(index)
  }

  get filteredItems() {
    const { query } = this.state;
    const q = query.toLowerCase();
    const { items } = this.props;
    return items.slice().filter(({ name, value }) => (
      name.toLowerCase().indexOf(q) > -1 ||
      value.toLowerCase().indexOf(q) > -1
    ))
  }

  render() {
    const { opened, value, query, highlighted } = this.state;
    const { onChange, disabled=false, name, items, search } = this.props
    const item = this.getItem(value) || {}
    const filteredItems = this.filteredItems

    return <div className={'select-wrapper' + (search ? ' searchable' : '')}>
      <input
        ref='input'
        type='text'
        readOnly
        disabled={disabled}
        value={item ? item.value || '' : ''}
        onClick={() => this.setState({ opened: !opened }, this.scrollToSelected)}
      />

      <i className='caret fa fa-caret-down' style={{ lineHeight: '49px' }} />

      <div className='dropdown-content' style={opened ? this.style : null} onKeyDown={this.handleKeyDown}>
        {opened  && search ? <div className='select-search' key='search'>
          <input type='text' value={query} onChange={this.handleOnQueryChange} autoFocus placeholder='Zoeken...' />
        </div> : null}

        <a className='close' onClick={() => this.handleOnChange('')} href='#'>
          <i className='far fa-times' />
        </a>

        <ul className='select-dropdown' ref='dropdown'>
          {filteredItems.length > 0 ? (
            filteredItems.map(({ name, value }) => (
              <li key={name} id={name} onClick={() => this.handleOnChange(name)} className={[name == highlighted ? 'highlighted' : '', name == item.name ? 'selected' : ''].join(' ')}>
                <span>{value}</span>
              </li>
            ))
          ) : (
            <li>
              <em>Geen items gevonden</em>
            </li>
          )}
        </ul>
      </div>
    </div>
  }
}
