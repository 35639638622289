import React from 'react';
import Page from '@/Page';
import autoBind from 'react-autobind';

import { LoginPage } from './login';


export class LoginExtensionPage extends LoginPage {
  constructor(props) {
    super(props)
    autoBind(this)
  }

  loginFromContext() {
    const { user } = this.context;
    if (user) {
      this.loginToExtension(user)
    }
  }

  handleOnMessage(e) {
    if (e.data.type && e.data.type == 'extension-login-response') {
      M.toast({ html: e.data.text })
      this.setState({ isSubmitting: false, loggedIn: true })
      return
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleOnMessage)
  }

  componentDidUnMount() {
    window.removeEventListener('message', this.handleOnMessage)
  }

  loginToExtension(user) {
    window.postMessage({ type: 'extension-login', user });
    this.setState({ isSubmitting: true })
  }

  handleUserLogin(user) {
    this.context.setUser(user);
    this.loginToExtension(user);
  }

  render() {
    const { user } = this.context;
    const { isSubmitting, loggedIn } = this.state;
    return user ? (
      <Page container>
        <p>Inloggen als <strong>{user.first_name || user.email}</strong>.</p>
        <button className='waves-effect waves-light btn' type='button' onClick={this.loginFromContext} disabled={isSubmitting || loggedIn}>
          {isSubmitting ? <i className='fad fa-spin fa-spinner-third left' /> : null}
          {loggedIn ? 'Ingelogd' : 'Inloggen'}
        </button>
      </Page>
    ) : super.render()
  }

}
