import React from "react";
import _ from "lodash";
import cn from "classnames";
import autoBind from "react-autobind";
import { Link } from "react-router-dom";
import { formatPlate, indexPlateRegex } from "Utils/plate";
import { numberWithDots } from "Utils/numbers";
import { titlefy, slugify } from "Utils/string";
import AppContext from "app-context";
import allFields from "Data/fields.json";
import allOptions from "Data/options.json";
import api from "js/api";

export default class Car extends React.Component {
  static contextType = AppContext;
  state = {
    activeTab: "images",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    M.Tooltip.init(document.querySelectorAll(".tooltipped"));
  }

  handleOnDelete() {
    this.props.handleOnDelete(this.value("id"));
  }

  handleOnSave(field, value) {
    this.setState({ editing: "" });
    this.props.handleOnSave(this.value("id"), { field, value });
  }

  value(field) {
    const { data } = this.props;
    if (data.user_data.hasOwnProperty(field)) {
      return data.user_data[field];
    }
    return data[field];
  }

  values(...fields) {
    const values = {};
    fields.forEach((field) => (values[field] = this.value(field)));
    return values;
  }

  editable(field) {
    const value = this.value(field);
    return (
      <div className="editing-field">
        {this[`edit_${field}`] || (
          <textarea
            value={value}
            name={field}
            onChange={(e) => {
              this.props.handleOnFieldChange(e.target, this.value("id"));
            }}
            autoFocus
            style={{
              lineHeight:
                value.indexOf("\n") == -1 ? "calc(2rem + 1px)" : "1rem",
            }}
          />
        )}
        <button
          type="button"
          className="waves-effect waves-light grey btn-floating btn-small"
        >
          <i
            className="far fa-save"
            onClick={() => this.handleOnSave(field, value)}
          />
        </button>
      </div>
    );
  }

  isEditable(field) {
    if (!this.props.canEdit) return;
    const nonEditableFields = ["rating", "title"];
    return nonEditableFields.indexOf(field) == -1;
  }

  get model() {
    const { brand, model } = this.values("brand", "model");
    if (!model) return brand;
    return (
      <>
        {brand} {model}
        {/* <Link to={`/aanbod?make=${slugify(brand)}&model=${slugify(model)}`} style={{ marginLeft: 4 }} className='tooltipped' data-tooltip='Toon vergelijkbare occasions'>
        <i className='far fa-cars' />
      </Link> */}
      </>
    );
  }

  get rating() {
    const { canEdit } = this.props;
    const { id, rating, notes } = this.values("id", "rating", "notes");
    const stars = _.range(0.5, 5.5, 0.5);
    return (
      <>
        <div className={cn("rating", { editable: canEdit })}>
          {stars.map((star) => (
            <i
              key={star}
              className={"fa-star-half " + (rating >= star ? "fas" : "far")}
              onClick={
                canEdit ? () => this.props.setRating(id, star) : undefined
              }
            />
          ))}
          {/* <i
            className={"fa-star " + (rating >= 1 ? "fas" : "far")}
            onClick={() => this.props.setRating(id, 1)}
          ></i>
          <i
            className={"fa-star " + (rating >= 2 ? "fas" : "far")}
            onClick={() => this.props.setRating(id, 2)}
          ></i>
          <i
            className={"fa-star " + (rating >= 3 ? "fas" : "far")}
            onClick={() => this.props.setRating(id, 3)}
          ></i>
          <i
            className={"fa-star " + (rating >= 4 ? "fas" : "far")}
            onClick={() => this.props.setRating(id, 4)}
          ></i>
          <i
            className={"fa-star " + (rating >= 5 ? "fas" : "far")}
            onClick={() => this.props.setRating(id, 5)}
          ></i> */}
        </div>
        <a
          className={
            "notes teal-text fa-sticky-note tooltipped " +
            (notes ? "fas" : "far")
          }
          onClick={canEdit ? () => this.props.handleOnNotes(id) : undefined}
          href="#"
          data-tooltip={
            notes
              ? `<div style="white-space-collapse: preserve;">${notes}</div>`
              : "Notitie"
          }
          data-position="top"
        />
      </>
    );
  }

  get edit_licence_plate() {
    const licence_plate = this.value("licence_plate");
    const value = formatPlate(licence_plate) || licence_plate;
    const index = indexPlateRegex(licence_plate);

    const handleOnChange = (e) => {
      const re = /^[\w\-]+$/;
      let value = e.target.value;
      if (value === "" || re.test(value)) {
        value = value.replace(/\-/g, "").toUpperCase().substr(0, 6);
        this.props.handleOnFieldChange(
          { name: "licence_plate", value },
          this.value("id")
        );
      }
    };

    return (
      <div className={"plate " + (index >= 0 && index < 3 ? "old" : "new")}>
        <span>NL</span>
        <input
          type="text"
          autoFocus
          className="browser-default"
          value={value}
          onChange={handleOnChange}
        />
      </div>
    );
  }

  get licence_plate() {
    const licence_plate = this.value("licence_plate");
    const index = indexPlateRegex(licence_plate);

    return licence_plate ? (
      <div className={"plate " + (index >= 0 && index < 3 ? "old" : "new")}>
        <span>NL</span>
        <div>{formatPlate(licence_plate) || licence_plate}</div>
      </div>
    ) : null;
  }

  get mileage() {
    const mileage = this.value("mileage");
    if (!mileage) return;
    return <div className="mileage">{numberWithDots(mileage)} km</div>;
  }

  get price() {
    const price = this.value("price");
    if (!price) return;
    return <div className="price">€ {numberWithDots(price)}</div>;
  }

  get data() {
    const data = this.value("data");
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div>
            {key}: {data[key]}
          </div>
        ))}
      </div>
    );
  }

  getRDWData() {
    const { id, comparison } = this.values("id", "comparison");
    const { sideToSideData } = this.state;
    if (sideToSideData) return;
    api.comparisons.occasion
      .sideToSide(comparison, id)
      .then((sideToSideData) => this.setState({ sideToSideData }));
  }

  handleOpenModal() {
    M.Modal.init(this.modal).open();
    M.Carousel.init(this.carousel);
  }

  handlePDF() {
    const { id, comparison } = this.values("id", "comparison");
    this.setState({ isSubmitting: true });
    api.comparisons.occasion
      .pdf(comparison, id)
      .then((response) => response.blob())
      .then((pdf) => {
        const data = window.URL.createObjectURL(pdf);
        var link = document.createElement("a");
        link.href = data;
        link.download = "auto-rapport.pdf";
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(data), 100);
      })
      .then(() => this.setState({ isSubmitting: false }));
  }

  calculateTranslate(e) {
    e.persist();
    const img = e.target;
    const x = e.clientX;
    const y = e.clientY;
    const width = img.width;
    const height = img.height;
    const left = -((x - window.innerWidth / 2) * 0.5);
    const top = -((y - window.innerHeight / 2) * 0.5);

    return {
      top,
      left,
    };
  }

  handleMouseMove(e) {
    this.setState(this.calculateTranslate(e));
  }

  getImageIndex(idx) {
    const { images } = this.props.data;
    if (idx === -1) {
      return images.length - 1;
    } else if (idx === images.length) {
      return 0;
    } else {
      return idx;
    }
  }

  prev() {
    this.setState({
      largeImageIndex: this.getImageIndex(this.state.largeImageIndex - 1),
      zoomedIn: false,
    });
  }

  next() {
    this.setState({
      largeImageIndex: this.getImageIndex(this.state.largeImageIndex + 1),
      zoomedIn: false,
    });
  }

  render() {
    const { canEdit, children, fields, refreshing, options, data } = this.props;
    const {
      id,
      url,
      user,
      user_data,
      domain,
      title,
      images,
      licence_plate,
      note,
    } = data;
    const {
      isSubmitting,
      editing,
      activeTab,
      sideToSideData,
      largeImageIndex,
      zoomedIn,
      left,
      top,
    } = this.state;
    const imageIndex = user_data.imageIndex || 0;
    const { thumbnail, file } = images[imageIndex] || {};

    const infoFields = allFields
      .slice()
      .filter(
        (field) =>
          this.value(field).toString() != "" && allOptions.indexOf(field) == -1
      );

    const ratingIndex = infoFields.indexOf("rating");
    if (ratingIndex != -1) {
      infoFields.splice(ratingIndex + 1, 0, "notes");
    }

    const optionFields = allOptions
      .slice()
      .filter((field) => this.value(field).toString() != "");

    const largeImage = images[largeImageIndex];

    return (
      <div className="car">
        {children}

        <div
          className="modal modal-fixed-footer"
          ref={(modal) => (this.modal = modal)}
          data-href={`#occasion-${id}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 1rem .5rem",
              backgroundColor: "#fff",
            }}
          >
            <a href={url} target="_blank" className="domain">
              <span>{domain}</span>
            </a>
            <div
              className="title"
              style={{ marginLeft: "1rem", height: "auto", marginBottom: "0" }}
            >
              {title}
            </div>
          </div>

          <div className="tabs tabs-fixed-width" ref="tabs">
            <div className="tab">
              <a
                href="#"
                onClick={() => this.setState({ activeTab: "images" })}
                className={activeTab == "images" ? "active" : null}
              >
                Foto's
              </a>
            </div>
            <div className="tab">
              <a
                href="#"
                onClick={() => this.setState({ activeTab: "general" })}
                className={activeTab == "general" ? "active" : null}
              >
                Occasion
              </a>
            </div>
            <div className="tab">
              <a
                href="#"
                onClick={() =>
                  this.setState({ activeTab: "rdw" }, this.getRDWData)
                }
                className={activeTab == "rdw" ? "active" : null}
              >
                RDW
              </a>
            </div>
          </div>

          <div className="modal-content">
            <div style={{ display: activeTab == "images" ? "block" : "none" }}>
              <div className="tiles">
                {images.map((i, index) => (
                  <div
                    className="tile"
                    key={i.thumbnail}
                    onClick={() =>
                      this.setState({
                        largeImageIndex: index,
                        zoomedIn: false,
                      })
                    }
                  >
                    <img src={i.thumbnail} />
                    {index == imageIndex ? (
                      <a
                        href="#"
                        className="btn-floating btn-small waves-effect waves-light favorite"
                      >
                        <i className="fa-star fas" />
                      </a>
                    ) : null}
                  </div>
                ))}
                {largeImage ? (
                  <div className="tile large">
                    <img
                      src={largeImage.file}
                      className={"no-select" + (zoomedIn ? " zoomed-in" : "")}
                      style={
                        zoomedIn
                          ? {
                              transform: `scale(2) translate(${left}px, ${top}px)`,
                            }
                          : null
                      }
                      onClick={(e) =>
                        this.setState({
                          zoomedIn: !zoomedIn,
                          ...this.calculateTranslate(e),
                        })
                      }
                      onMouseMove={zoomedIn ? this.handleMouseMove : null}
                    />
                    <span className="count">
                      <i className="far fa-image" />
                      {largeImageIndex + 1} / {images.length}
                    </span>
                    <a
                      href="#"
                      className="btn-floating btn-small waves-effect waves-light favorite"
                      onClick={() =>
                        this.props.handleOnSave(id, {
                          field: "imageIndex",
                          value: largeImageIndex,
                        })
                      }
                    >
                      <i
                        className={
                          "fa-star " +
                          (largeImageIndex == imageIndex ? "fas" : "far")
                        }
                      />
                    </a>
                    <a
                      href="#"
                      className="btn-floating btn-small waves-effect waves-light close"
                      onClick={() => this.setState({ largeImageIndex: null })}
                    >
                      <i className="far fa-times" />
                    </a>
                    <a
                      href="#"
                      className="btn-floating btn-small waves-effect waves-light prev"
                      onClick={() => this.prev()}
                    >
                      <i className="far fa-chevron-left" />
                    </a>
                    <a
                      href="#"
                      className="btn-floating btn-small waves-effect waves-light next"
                      onClick={() => this.next()}
                    >
                      <i className="far fa-chevron-right" />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ display: activeTab == "general" ? "block" : "none" }}>
              <table>
                <tbody>
                  {infoFields.map((field) => (
                    <tr key={field}>
                      <th>{__(field)}</th>
                      <td
                        style={{ textAlign: "right", whiteSpace: "pre-line" }}
                      >
                        {this[field] || this.value(field)}
                      </td>
                    </tr>
                  ))}
                  {optionFields.length ? (
                    <>
                      <tr>
                        <td colSpan="2">
                          <div
                            className="extra-options"
                            style={{ paddingLeft: "0", paddingRight: "0" }}
                          >
                            Extra opties
                          </div>
                        </td>
                      </tr>
                      {optionFields.map((field) => (
                        <tr key={field}>
                          <th>{__(field)}</th>
                          <td style={{ textAlign: "right" }}>
                            {this[field] || this.value(field)}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
            <div style={{ display: activeTab == "rdw" ? "block" : "none" }}>
              {typeof sideToSideData != "undefined" ? (
                Object.keys(sideToSideData).length > 0 ? (
                  <table>
                    <tbody>
                      {Object.entries(sideToSideData).map(([field, value]) => (
                        <tr key={field}>
                          <th>{__(field)}</th>
                          {/* {this.value(field) ? (
                            <td style={{ textAlign: "right" }}>
                              {this.value(field)}
                            </td>
                          ) : ( */}
                          <td
                            style={{ textAlign: "right" }}
                            // className="set-value"
                            // onClick={() =>
                            //   this.props.handleOnSave(id, { field, value })
                            // }
                          >
                            {value}
                          </td>
                          {/* )} */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>Geen gegevens gevonden</p>
                )
              ) : (
                <p>Aan het laden...</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {/* <button
              className="waves-effect waves-green btn-flat"
              type="button"
              onClick={this.handlePDF}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <i className="fad fa-spin fa-spinner-third left" />
              ) : null}
              Afdrukken
            </button> */}
            <button
              className="modal-close waves-effect waves-green btn-flat"
              type="button"
            >
              {__("Close")}
            </button>
          </div>
        </div>

        {thumbnail ? (
          <div
            className="image"
            onClick={this.handleOpenModal}
            style={{ backgroundImage: `url(${thumbnail})` }}
          >
            <span className="count">
              <i className="far fa-image" /> {images.length}
            </span>
          </div>
        ) : (
          <div
            className="image occasion-placeholder"
            onClick={this.handleOpenModal}
          >
            <i className="far fa-car" />
          </div>
        )}

        <div className="title" onClick={this.handleOpenModal}>
          {title}
        </div>

        {url ? (
          <a href={url} target="_blank" className="domain">
            <span>{domain}</span>
          </a>
        ) : (
          <span className="domain" />
        )}

        {refreshing ? (
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-green-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <ul>
              {fields.map((field) => (
                <li
                  key={field}
                  data-field={__(field)}
                  onClick={() =>
                    editing === field || !this.isEditable(field)
                      ? null
                      : this.setState({ editing: field })
                  }
                >
                  {editing === field
                    ? this.editable(field)
                    : this[field] || this.value(field)}
                </li>
              ))}
            </ul>
            {options.length ? (
              <>
                <div className="extra-options">Extra opties</div>
                <ul>
                  {options.map((field) => (
                    <li
                      key={field}
                      onClick={() =>
                        this.isEditable(field)
                          ? this.setState({ editing: field })
                          : null
                      }
                    >
                      {editing === field
                        ? this.editable(field)
                        : this[field] || this.value(field)}
                    </li>
                  ))}
                </ul>
              </>
            ) : null}

            <div className="added-by">
              Toegevoegd door{" "}
              <strong>
                {user ? user.first_name || user.email : "anoniem"}
              </strong>
            </div>

            {canEdit && (
              <div className="actions">
                <a
                  className="teal-text far fa-sync tooltipped"
                  onClick={() => this.props.handleOnRefresh(id)}
                  href="#"
                  data-tooltip="Verversen"
                  data-position="top"
                />
                <a
                  className="orange-text far fa-undo tooltipped"
                  onClick={() => this.props.handleOnReset(id)}
                  href="#"
                  data-tooltip="Resetten"
                  data-position="top"
                  style={
                    Object.keys(user_data).length ? null : { display: "none" }
                  }
                />
                <a
                  className="red-text far fa-trash-alt margin-left-auto tooltipped"
                  onClick={this.handleOnDelete}
                  href="#"
                  data-tooltip="Verwijderen"
                  data-position="top"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
